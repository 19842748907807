<template>
  <div>
   <Nav ref="navchild"></Nav>
    <div class="banner-width">
      <Banner></Banner>
    </div>
    <div class="mainwidth">
      <Title>
        <template v-slot:one>
          <el-divider content-position="center">科普讲堂</el-divider>
        </template>
        <template v-slot:two>
          <span class="sub1">科普知识</span>
        </template>
      </Title>
      <div class="content">
        <div class="contenttitle">{{theme}}</div>
        <div style="display: flex;
  justify-content: center;">
          <div class="source">
            主讲人：{{keynoteSpeaker }}
            <el-divider direction="vertical"></el-divider>
          </div>

          <div class="date">发布日期：{{releaseTime }}</div>
        </div>
        <div class="center" v-html="article">{{article}}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template> 

<script>
import Nav from "@/views/pc/components/Nav";
import Banner from "@/views/pc/components/Banner";
import Footer from "@/views/pc/components/Footer";
import Title from "@/views/pc/components/Title";
import { kpjtListDetails } from "@/api/pc.js";
import { appTitle } from '@/utils/get-page-title.js'
export default {
  name: "LectureDetail",
  data() {
    return {
      id: this.$route.params.id,
      theme: "",
      releaseTime: "",
      article: "",
      keynoteSpeaker: "",
       flag:2
    };
  },
  components: { Nav, Footer, Title, Banner },
  created() {
    this.getKpjtListDetails();
  },
        mounted() {
    var arr = this.$route.path.split("/");
    this.$refs.navchild.setNavIndex("/" + arr[1]);
  },
  methods: {
    getKpjtListDetails() {
      let params = {
        id: this.id,
         flag:this.flag
      };
      kpjtListDetails(params).then(res => {
        this.theme = res.data.theme;
        document.title = res.data.theme + '-' + appTitle
        this.releaseTime = res.data.releaseTime;
        this.article = res.data.article;
        this.keynoteSpeaker = res.data.keynoteSpeaker;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.el-divider {
  background: linear-gradient(
    to right,
    #fff,
    #e2e4e9,
    #d8d8d8,
    #d8d8d8,
    #e2e4e9,
    #fff
  );
}
.sub1 {
    background: #067fd8;
    color: #fff;
    width: 140px;
    text-align: center;
    height: 22px;
    line-height: 22px;
}
.el-divider__text {
  background: #fff;
}
.banner-width {
  background: url("~@/assets/pcimg/bg1.jpg")  center no-repeat;
}
.mainwidth {
  width: 1200px;
  margin: 0 auto;

  .content {
    min-height: calc(100vh - 412px);
    margin-top: 40px;
    .contenttitle {
      font-size: 36px;
      font-weight: bold;
      text-align: center;
    }
    .date {
      font-size: 18px;
      color: #999;
      padding-top: 10px;
      text-align: center;
    }

    .source {
      font-size: 18px;
      color: #999;
      padding-top: 10px;
      text-align: center;
      padding-right: 8px;
    }
    .center {
      line-height: 30px;
      margin-top: 20px;
      img {
        margin-top: 20px;
      }
    }
  }
  .list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>